<template>
  <section>
    <EditableProducts />
    <ReceiptSend />
    <PayMethods />
  </section>
</template>

<script>
import EditableProducts from "./EditableProducts";
import ReceiptSend from "./ReceiptSend";
import PayMethods from "./PayMethods";

export default {
  components: {
    EditableProducts,
    ReceiptSend,
    PayMethods,
  },
};
</script>
