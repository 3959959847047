<template>
  <div v-if="!isLoading" class="menu-form">
    <Header />
    <Merchant />
    <section class="main">
      <Checkout />
    </section>
    <Footer />
  </div>
</template>

<script>
import Checkout from "@/components/checkout/index";
import getMenuInfo from "@/mixins/getMenuInfo";
import setRoute from "@/mixins/setRoute";
import Footer from "@/components/Footer";

export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    Footer,
    Checkout,
  },
};
</script>
