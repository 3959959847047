<template>
  <form class="feedbackForm">
    <h2 class="order__title feedbackForm__title">Способ связи</h2>

    <div v-for="item in feedback" :key="item.name" class="feedbackForm__input">
      <MaskedInput
        v-if="item.name === 'phone'"
        v-model="feedback.find((field) => field.name === item.name).value"
        @input="changeInput"
        type="text"
        class="order__input feedbackForm__input"
        :class="{ invalid: !feedBackNotEmpty }"
        :mask="phoneMask"
        placeholder="Номер телефона"
        :required="item.required"
      >
      </MaskedInput>

      <label v-if="item.name === 'message'">
        <input
          v-model="feedback.find((field) => field.name === item.name).value"
          @input="changeInput"
          :required="item.required"
          type="text"
          class="order__input feedbackForm__input"
          placeholder="Комментарий к заказу"
        />
      </label>
    </div>
  </form>
</template>

<script>
import MaskedInput from "vue-text-mask";
import { mapGetters, mapMutations } from "vuex";
import { FEEDBACK_CHANGE, FEEDBACK_EMPTY_CHANGE } from "@/store/mutations.type";
import { masks } from "@/constants";

export default {
  data: () => ({
    feedback: null,
  }),
  components: {
    MaskedInput,
  },
  computed: {
    ...mapGetters(["feedbackBlock", "feedBackNotEmpty"]),
    phoneMask: () => masks.phone.ru,
  },
  methods: {
    ...mapMutations({
      changeFeedbackForm: FEEDBACK_CHANGE,
      feedbackEmptyChange: FEEDBACK_EMPTY_CHANGE,
    }),
    changeInput() {
      this.changeFeedbackForm(this.feedback);
      this.feedbackEmptyChange(true);
    },
  },
  mounted() {
    this.feedback = this.feedbackBlock?.body.map((item) => ({
      ...item,
      value: "",
    }));
    this.changeInput();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/feedback-form.scss";
</style>
